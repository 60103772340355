html {
  /* height: 100vh; */
  /* height: 100%; */

  scrollbar-width: thin;
  scroll-behavior: smooth;
}
body {
  /* min-height: 100vh; */
  /* min-height: 100%; */
  /* height: 100%; */

  position: relative;
}

html, body {
  /* margin: 0; padding: 0; height: 100%; */
  /* position: relative; */
}

body {
  /* height: 100%; */
  /* min-height: 100%; */
}

.App {
  /* height: 100vh; */
  /* overflow: hidden; */
}

#navbar {
  /* Brown instead of the normal dark grey. */
  background-color: rgb(87, 70, 51) !important;
  /* A little bit darker version of the above. */
  background-color: rgb(72, 56, 38) !important;
  border-bottom: 2px solid #51432dd9;
}

.navbar-brand {
  /* font-family: 'Bebas Neue', cursive; */
  font-family: 'Open Sans', sans-serif;
}

.home, .portfolio, .contact {
  padding-top: 80px;
}

.home {
  background-image: 
    /* Transparent black gradient on top to darken the background image */
    linear-gradient(
      rgba(0, 0, 0, .4), 
      rgba(0, 0, 0, .4)
    ), 
    url('/images/beverage-black-coffee-caffeine-606543.jpg');
  /* width: 1280px; */
  /* height: 550px; */
  /* height: 100%; */
  /* min-height: 100%; */

  /* height: 100vh; */
  /* overflow: auto; */

  min-height: 100vh;

  /* overflow: hidden; */
  
  background-color: #cccccc; /* Used if the image is unavailable */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */

  text-align: center;
}

.home-name {
  /* font-family: 'Bebas Neue', cursive; */
  /* font-family: 'Open Sans', sans-serif; */
  /* font-family: 'Kalam', cursive; */
  /* font-family: 'Aladin', cursive; */
  /* font-family: 'Nova Mono', monospace; */
  font-family: 'B612 Mono', monospace;
  /* font-size: 100px; */
  /* font-size: 6rem; */
  font-size: 5rem;
  color: white;
  /* margin: 0 auto; */
  text-align: center;
  display: block;
  text-shadow: 2px 5px 5px #424242;

  /* margin-bottom: 0; */
}

.tagline {
  /* font-size: 36px; */
  font-style: italic;  
  font-family: 'Open Sans', sans-serif;

  font-size: 2.2rem;
  /* color: #f3f3f3; */
  color: white;
  text-align: center;
  display: block;
  margin-top: -20px;
  text-shadow: 2px 5px 5px #424242;
}

.bio {
  text-align: left;
  font-size: 1.1rem;
  margin: 20px auto 0;
  display: block;
  width: 650px;

  color: white;
  text-shadow: 2px 5px 5px #424242;

  margin-bottom: 30px;
}

.home-contact-heading {
  font-size: 2rem;
  color: white;
  text-align: center;
  display: block;
  text-shadow: 2px 5px 5px #424242;
  margin-top: 20px;
  margin-bottom: -10px;
}

.portfolio {
  /* background-color: #505050; */
  /* Light gray background */
  /* background-color: #636363; */
  /* Blue-ish background */
  /* background-color: rgb(78, 114, 128); */
  /* Brown background */
  /* background-color: rgb(99, 66, 43); */
  /* Coffee-ish background */
  background-color: rgb(155, 108, 54);
  /* A darker coffee color (the modal background can be lighter, like the above color) */
  background-color: rgb(111, 82, 50);
  /* A little more faded than the above, which might be more pleasing. */
  background-color: rgb(117, 94, 69);
  /* The modal shouldn't factor into the color of the page. This is pretty good. It's like the original, but less orange. */
  background-color: rgb(147, 109, 65);

  /* background-size: cover; */
  /* height: 550px; */

  /* height: 100vh; */
  /* overflow: auto; */

  min-height: 100vh;
  /* height: 100%; */
}

.portfolio-text {
  font-family: 'B612 Mono', monospace;

  font-size: 3rem;
  line-height: 4rem;
  color: white;
  /* margin: 0 auto; */
  text-align: center;
  display: block;
  /* text-shadow: 2px 5px 5px #424242; */
  text-shadow: 2px 5px 5px rgba(0, 0, 0, .25);

  /* margin-bottom: 10px; */
  margin-bottom: 30px;
}
@-moz-document url-prefix() {
  .portfolio-text {
    text-decoration: underline;
    text-underline-offset: 15px;
    margin-bottom: 30px;
  }
}

.project {
  margin-bottom: 20px;
}

.project-title {
  /* font-size: 1.4rem; */
  font-size: 1.8rem;
  display: block;
  color: white;
  /* text-shadow: 2px 5px 5px #424242; */
  text-shadow: 2px 4px 5px rgba(0, 0, 0, .2), 
  -2px 4px 5px rgba(0, 0, 0, .2);
  margin-bottom: 6px;
  font-family: Futura;
}

.project-img {
  width: 224px;
  height: 125px;
  cursor: pointer;
  transition: transform .25s;
  box-shadow: /* Reduced the shadow to mostly transparent */
    4px 4px 10px rgba(0, 0, 0, .2), 
    -4px 4px 10px rgba(0, 0, 0, .2);
  /* For images (project thumbnails) that aren't the exact size. */
  object-fit: cover;
  object-position: top;
}

.project.project-selected .project-img, .project-img:hover {
  /* box-shadow: 
    4px 4px 10px #222222, 
    -4px -4px 10px #222222; */
  /* Reduced the shadow to mostly transparent */
  box-shadow:
    6px 6px 15px rgba(0, 0, 0, .2), 
    -6px 6px 15px rgba(0, 0, 0, .2);
  /* width: 235px; */
  /* height: 147px; */
  transform: scale(1.05);
}

.project-tech {
  font-size: 1rem;
  display: block;
  color: white;
  font-style: italic;
  margin-top: 2px;
  /* color: #dadada; */ /* Light grey */
}

.project-description  {
  text-align: left;
  margin-top: 20px;
  padding: 15px;
  color: white;
  background: linear-gradient(
    rgba(0,0,0,.4), rgba(0,0,0,.4)
  );
  border-radius: 5px;
}

/* Color for the project modal links (overriding Bootstrap) */
.project-modal a {
  color: white;
  text-decoration: none;
  display: inline-block;
  transition: all .25s;
}
.project-modal a:hover {
  color: #dbdbdb;
  transform: scale(.98);
}

.contact {
  /* background-color: #505050; */
  background-color: #505077;
  /* height: 100%; */
  /* height: 600px; */
  height: 100vh;
  color: white;
}
.contact-text {
  font-size: 3rem;
  color: white;
  text-align: center;
  display: block;
  text-shadow: 2px 5px 5px #424242;
  margin-bottom: 10px;
}
.contact-icons {
  /* margin-top: 80px; */
  margin: 0 auto;
  width: 800px;
  text-align: center;
  filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, .4));
  /* transition:  */
}
.contact-icons svg {
  margin: 25px;
  transition: color .25s;
}
.contact-icons a {
  color: white;
}
.contact-icons svg:hover {
  color: #dbdbdb;
  cursor: pointer;
}
.contact-icons-separate {
  width: 280px;
}


/* Mobile optimization */
@media screen and (max-width: 768px) /* and (orientation: portrait)  */{
  .App {
    /* height: 100%; */
    /* overflow: initial; */
  }
  .home {
    /* height: 740px; */
    background-position: 80% 50%; /* Have the coffee cup as the background on mobile/portrait */
  }
  .home-name {
    /* font-size: 2.8rem; */
    font-size: 2.2rem;
  }
  .tagline {
    font-size: 1.4rem;
    margin: initial;
  }
  .bio {
    /* width: 300px; */
    /* width: 100%; */
    width: initial;
    margin: 20px;
  }
  .portfolio {
    /* height: 100%; */
    height: 93%;
    overflow: scroll;
  }
  .portfolio-text {
    margin-bottom: 10px;
  }
  .project {
    margin-top: 20px;
  }
  .project-title {
    font-size: 1.6rem;
  }
  .project-img {
    width: calc(400px * .7);
    height: calc(224px * .7);
  }
  .contact-icons {
    width: initial;
  }
}

/* Large screen settings */
@media screen and (min-width: 992px) {
  .project-img {
    /* Fixed size */
    /* width: calc(400px * .75); */
    /* height: calc(224px * .75); */

    /* Responsive */
    width: calc(30vw);
    height: calc(30vw * 224/400);
  }
}

/* Extra-Large screen optimization */
@media screen and (min-width: 1280px) {
  .project-img {
    width: 400px;
    height: 224px;
  }
}





/** Bootstrap Dark Modal Styles **/
.modal-open .modal, .btn:focus {
  outline: none !important;
}

.modal.dark { 
  /* background-color: #2d3032;  */
  color: white;
}
.modal.dark .modal-body {
  background: #40464b;
  background-color: rgb(155, 108, 54);
  /* background-color: rgb(142, 97, 45); */
  background-color: rgb(147, 110, 67);
  /* Changing the font and adding a shadow for legibility. */
    /* font-family: Futura; */
    text-shadow: 1px 1px #845b3c;
    /* text-shadow: 2px 2px 2px #845b3c; */
}

.modal-body {
  border-radius: 0px 0px .3rem .3rem;
}

.modal.dark .modal-content {
  background-color: initial;
}

.modal.dark .modal-header {
  background: #2d3032;
  background-color: rgb(121, 93, 61);
  border-bottom: 1px solid #2a2c2e;
  /* The border-bottom now is brown to match the new brown modal. */
  border-bottom: 1px solid #7d5d3a;
  font-family: Futura;
}
.modal.dark .modal-header h1, .modal.dark .modal-header h2, .modal.dark .modal-header h3, .modal.dark .modal-header h4 {
  color: #ccc;
}

.modal.dark button {
  color: white;
}

.modal.dark .modal-footer {
  background: #2d3032;
  border-top: 1px solid #2a2c2e;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}